import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Produced for Motoko Bootcamp, this guide shows a minimal example of how to add authentication to your frontend and manage updates in a Motoko canister.`}</p>
    <p>{`The code for this example is available at `}<a parentName="p" {...{
        "href": "https://github.com/krpeacock/access-control-tutorial"
      }}>{`https://github.com/krpeacock/access-control-tutorial`}</a>{`. Here is a video I also produced for the 2023 Motoko Bootcamp:`}</p>
    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/5m3Wn8tX9RM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <h2 {...{
      "id": "concepts"
    }}><a parentName="h2" {...{
        "href": "#concepts"
      }}>{`Concepts`}</a></h2>
    <p>{`What is "Access Control"? It is a broad term that essentially amounts to "who is allowed to access or change stuff". In cryptography, we accomplish this using `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Digital_signature"
      }}>{`cryptographic signatures
`}</a>{`. For the Internet Computer, we take these signatures and transform the public key into a (relatively) short format called a `}<a parentName="p" {...{
        "href": "https://internetcomputer.org/docs/current/references/ic-interface-spec/#principal"
      }}>{`Principal`}</a>{`, which looks like a series of characters, separated by a hyphen (`}<inlineCode parentName="p">{`-`}</inlineCode>{`).`}</p>
    <p>{`Since the Internet Computer automatically validates the signature used to make a call before it even reaches your canister's code, this makes it very convenient to use these Principals as a way to identify a unique user. Notably, Principals can be both a user, or another canister. We use Principals in a number of contexts - the `}<inlineCode parentName="p">{`controllers`}</inlineCode>{` that are authorized to make changes to an application are Principals, and the `}<inlineCode parentName="p">{`caller`}</inlineCode>{` of any method will also be represented as a Principal. Thanks to the properties of cryptographic signatures, you can be confident that a Principal cannot be faked. It will always be the same person, unless they have had their private key compromised.`}</p>
    <h2 {...{
      "id": "backend-logic"
    }}><a parentName="h2" {...{
        "href": "#backend-logic"
      }}>{`Backend Logic`}</a></h2>
    <p>{`In Motoko, there are a few tricks you can use for access control purposes. Let's start with the `}<inlineCode parentName="p">{`creator`}</inlineCode>{` of the canister itself.`}</p>
    <h3 {...{
      "id": "creator-id"
    }}><a parentName="h3" {...{
        "href": "#creator-id"
      }}>{`Creator ID`}</a></h3>
    <p>{`The simplest way you can set up your Motoko canister is to declare an actor with something like this:`}</p>
    <pre {...{
      "className": "language-swift"
    }}><code parentName="pre" {...{
        "className": "language-swift"
      }}><span parentName="code" {...{
          "className": "token keyword"
        }}>{`actor`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`public`}</span>{` query `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`func`}</span>{` `}<span parentName="code" {...{
          "className": "token function-definition function"
        }}>{`sayHi`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Text`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"Hi"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <p>{`This is a great place to start, but there is more information you can gather if you need it. First, you can learn the Principal that was responsible for creating the canister in the first place. If you are using `}<a parentName="p" {...{
        "href": "https://internetcomputer.org/docs/current/references/cli-reference/dfx-parent/"
      }}>{`dfx`}</a>{`, you can check the Principal by labeling the actor as `}<inlineCode parentName="p">{`shared`}</inlineCode>{`.`}</p>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}><span parentName="code" {...{
          "className": "token function"
        }}>{`shared`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` caller `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` actor `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`self`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// ...`}</span>{`
`}</code></pre>
    <p>{`A pattern that is common for clarity purposes is to rename the `}<inlineCode parentName="p">{`caller`}</inlineCode>{` to `}<inlineCode parentName="p">{`"creator"`}</inlineCode>{`, which looks like this:`}</p>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}><span parentName="code" {...{
          "className": "token function"
        }}>{`shared`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` caller `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` creator `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` actor `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`self`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// ...`}</span>{`
`}</code></pre>
    <p>{`Then, inside your code, you have access to both `}<inlineCode parentName="p">{`creator`}</inlineCode>{`, which will tell you the Principal that created the canister, as well as `}<inlineCode parentName="p">{`self`}</inlineCode>{`, which will give you the ability to reference the canister's own Principal.`}</p>
    <h3 {...{
      "id": "caller-id"
    }}><a parentName="h3" {...{
        "href": "#caller-id"
      }}>{`Caller ID`}</a></h3>
    <p>{`The same pattern is available for your methods. If we take the sayHi func above, we can modify it into a "shared" query func, which will give the function access to the caller's Principal.`}</p>
    <pre {...{
      "className": "language-swift"
    }}><code parentName="pre" {...{
        "className": "language-swift"
      }}><span parentName="code" {...{
          "className": "token keyword"
        }}>{`public`}</span>{` shared `}<span parentName="code" {...{
          "className": "token function"
        }}>{`query`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`caller`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`func`}</span>{` `}<span parentName="code" {...{
          "className": "token function-definition function"
        }}>{`sayHi`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Text`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"Hi, "`}</span></span>{` # `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Principal`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`toText`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`caller`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <h2 {...{
      "id": "frontend-logic"
    }}><a parentName="h2" {...{
        "href": "#frontend-logic"
      }}>{`Frontend Logic`}</a></h2>
    <p>{`In the code sample I've created here, I have written a simple application that tracks the number of times that a particular caller has called the `}<inlineCode parentName="p">{`increment`}</inlineCode>{` method.`}</p>
    <p>{`Most of the logic is simply about enabling this behavior, but I do want to share two useful authentication strategies that the example shows off:`}</p>
    <h3 {...{
      "id": "ed25519-from-seed"
    }}><a parentName="h3" {...{
        "href": "#ed25519-from-seed"
      }}>{`Ed25519 from Seed`}</a></h3>
    <p>{`Since it's possible to generate an Ed25519KeyIdentity using a `}<inlineCode parentName="p">{`seed`}</inlineCode>{`, this means we can use a passphrase or some other secret to generate an identity. The example does this with a `}<inlineCode parentName="p">{`password`}</inlineCode>{` input, and enables you to have reproducible identities using a simple text input. Here's the snippet that makes this work:`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`function`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`seedToIdentity`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`seed`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` seedBuf `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Uint8Array`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`ArrayBuffer`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`32`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`seed`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`length`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`&&`}</span>{` seed`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`length`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`&&`}</span>{` seed`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`length`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<=`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`32`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    seedBuf`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`set`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`TextEncoder`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`encode`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`seed`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Ed25519KeyIdentity`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`generate`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`seedBuf`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword null nil"
        }}>{`null`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <h3 {...{
      "id": "internet-identity-web-component"
    }}><a parentName="h3" {...{
        "href": "#internet-identity-web-component"
      }}>{`Internet Identity Web Component`}</a></h3>
    <p>{`The II web component is a project I spent some time on a few months back. It takes the `}<inlineCode parentName="p">{`@dfinity/auth-client`}</inlineCode>{` package and abstracts it with a web component that you can drop into your page with a little less configuration and some nice features. After you log in, the `}<inlineCode parentName="p">{`identity`}</inlineCode>{` will be accessible by just checking the button's `}<inlineCode parentName="p">{`identity`}</inlineCode>{` attribute.`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}>{`prepareLoginBotton`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`renderCb`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`!`}</span>{`customElements`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`get`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"ii-login-button"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    customElements`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`define`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"ii-login-button"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`LoginButton`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Once the login button is ready, we can configure it to use Internet Identity`}</span>{`
  loginButton`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`addEventListener`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"ready"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`event`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
      `}<span parentName="code" {...{
          "className": "token dom variable"
        }}>{`window`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`location`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`host`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`includes`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"localhost"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`||`}</span>{`
      `}<span parentName="code" {...{
          "className": "token dom variable"
        }}>{`window`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`location`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`host`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`includes`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"127.0.0.1"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      loginButton`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`configure`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        loginOptions`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
          identityProvider`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token string"
          }}>{`http://`}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`process`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token property-access"
            }}>{`env`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`CANISTER_ID_INTERNET_IDENTITY`}</span><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token string"
          }}>{`.localhost:4943`}</span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
        `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  loginButton`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`addEventListener`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"login"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`event`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` identity `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` loginButton`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?.`}</span>{`identity`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token dom variable"
        }}>{`window`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`identity`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` identity`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token function"
        }}>{`renderCb`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// ...`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      